<template>
  <div class="w-full my-4">

    
    <!-- <div v-for="a in data" :key="a.id" class="md:w-1/4 px-4 mb-8">
            
      <DynamicImage :source="a.data" class="rounded shadow-md" />
      <div class="bg-gray-200 w-full mt-2">
        <span class="rounded mx-2 px-2 bg-red-900 text-white cursor-pointer" @click="deleteImage(a.id)">delete</span>
      </div>

    </div>
     -->
    
    <draggable v-model="data" item-key="id" @start="drag=true" @end="drag=false" @update="reorder" class="grid gap-4 grid-cols-2 md:grid-cols-3 lg-grid-cols-4">
      <template #item="{element}">
        <div class="rounded shadow-md mb-8">
          <div class="absolute m-2">
            <button type="button" class="inline-flex justify-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" @click="deleteImage(element.id, element.orientation)">
              <svg v-show="element.id === idDeleting" class="h-5 animate-spin rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white;" viewBox="0 0 24 24"></svg>
              <TrashCan32 class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
            </button>
            <button type="button" :class="element.highlight ? 'text-white bg-green-600 hover:bg-green-700':'text-white bg-black hover:bg-gray-700'" class="ml-2 inline-flex justify-center border border-transparent shadow-sm text-sm font-medium rounded-md focus:outline-none" @click="highlightImage(element.id)">
              <svg v-show="element.id === idHighligting" class="h-5 animate-spin rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white;" viewBox="0 0 24 24"></svg>
              <LogoTwitter32 class="flex-shrink-0 h-5 w-5" aria-hidden="true" /> Social media image?
            </button>
          </div>
          <DynamicImage :source="element.data"/>
        </div>
      </template>
    </draggable>

  
  </div>
</template>

<script>
import draggable from "vuedraggable";
import UploadService from '../../../services/admin/photos/service';
import DynamicImage from "../../DynamicImage.vue";
import { toRefs, watch, ref } from "vue";
import { TrashCan32, LogoTwitter32 } from '@carbon/icons-vue';

export default {
  components: {
    draggable,
    DynamicImage,
    TrashCan32,
    LogoTwitter32
  },
  props: {
    model: {
      type: String
    },
    id: {
      type: Number
    },
    photos: {
      type: Array
    },
  },
  setup(props) {
    const { photos } = toRefs(props)
    let data = photos;
    const idHighligting = ref(0);
    const idDeleting = ref(0);
    
    watch(() => photos, (first, second) => {
      data = second;
    }, {deep: true});

    return {
      data,
      drag: false,
      idDeleting,
      idHighligting
    };
  },
  methods: {
    reorder() {
      UploadService.reorderPhotos(this.data);
    },
    async deleteImage(id, orientation) {
      // orientation is only available on 'images' not 'photos'
      this.idDeleting = id;
      if (orientation) {
        await UploadService.deleteImage(id, 'image');
      } else {
        await UploadService.deleteImage(id, 'photo');
      }
      
      this.$emit("update-delete");
    },
    async highlightImage(id) {
      this.idHighligting = id;
      await UploadService.highlightImage(id, 'image');
      this.idHighligting = null;
      this.$emit("update-delete");
    }
  }
};
</script>

<style scoped></style>
